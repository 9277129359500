import { useTranslation } from 'summon-ui/intl'
import { Button } from 'summon-ui/mantine'
import VideoOverlay from '@components/VideoOverlay'
import useClaimG7Box from '../../hooks/useClaimG7Box'
import useClaimG7BoxSteps from '../../hooks/useClaimG7BoxSteps'
import useMockedSlotsStorage from '../../views/useMockedSlotsStorage'
import BoxTemplate from '../BoxTemplate/BoxTemplate'
import ClaimedRewardsModal from './ClaimedRewardsModal'

interface Props {
  g7BoxId: string
  unlockAt: string
  onClaimSuccess: () => void
}

const G7Box = ({ g7BoxId, unlockAt, onClaimSuccess }: Props) => {
  const t = useTranslation()
  const { step, setStep } = useClaimG7BoxSteps()
  const { updateSlotBoxById } = useMockedSlotsStorage()
  const { claim, isClaiming, rewards } = useClaimG7Box()

  const handleClaimSuccess = () => {
    setStep('box-animation')
  }

  const isFutureDate = unlockAt ? new Date(unlockAt) > new Date() : false

  const renderClaim = () => (
    <Button
      c='white'
      w='100%'
      loading={isClaiming}
      disabled={isClaiming}
      className='!bg-green-7'
      onClick={() => {
        claim(g7BoxId, handleClaimSuccess)
      }}
    >
      {t('Claim')}
    </Button>
  )

  return (
    <>
      <BoxTemplate
        countdownDate={new Date(unlockAt).toISOString()}
        ActionCTA={!isFutureDate ? renderClaim() : null}
        description={t('Unlocks In')}
      />

      {rewards && (
        <ClaimedRewardsModal
          open={step === 'box-rewards'}
          onClose={() => {
            setStep(null)
            updateSlotBoxById(g7BoxId, {
              variant: 'empty',
              price: 100,
              unlockDate: undefined
            })
            onClaimSuccess()
          }}
          rewards={rewards}
        />
      )}
      {step === 'box-animation' && (
        <VideoOverlay
          onClose={() => {
            setStep('box-rewards')
          }}
          sourceUrl='/lootboxes/legendary.webm'
        />
      )}
    </>
  )
}

export default G7Box
