import type { ReactNode } from 'react'
import { Countdown, G7Icon } from 'summon-ui'
import { FormattedMessage, useTranslation } from 'summon-ui/intl'
import { Button, Text } from 'summon-ui/mantine'
import VideoOverlay from '@components/VideoOverlay'
import type { BoxDetails } from '@generated/generates'
import useClaimG7Box from '../../../hooks/useClaimG7Box'
import useClaimG7BoxSteps from '../../../hooks/useClaimG7BoxSteps'
import useMockedSlotsStorage from '../../../views/useMockedSlotsStorage'
import ClaimedRewardsModal from '../../G7Box/ClaimedRewardsModal'
import CTABox from './CTABox'
import type { SlotBoxVariantElementsComponent } from './types'

interface Props {
  boxPrice?: number
  Image: ReactNode
  boxDetails: BoxDetails
  isPlaceholder: boolean
  onUnlock: () => void
  onUnlockAtOver: () => void
}

const Unlocking = ({
  boxPrice,
  Image,
  boxDetails,
  onUnlock,
  isPlaceholder,
  onUnlockAtOver
}: Props): SlotBoxVariantElementsComponent => {
  const t = useTranslation()
  const { step, setStep } = useClaimG7BoxSteps()
  const { claim, rewards } = useClaimG7Box()
  const { updateSlotBoxById } = useMockedSlotsStorage()

  const { id, unlockAt } = boxDetails

  const handleClaimSuccess = () => {
    setStep('box-animation')
  }

  return {
    TitleElement: (
      <Countdown
        from={new Date(unlockAt)}
        render={(humanFormattedCountdown) => {
          if (humanFormattedCountdown === '00m:00s') {
            onUnlockAtOver()
          }
          return (
            <Text fw='bold' size='xs' c='skyblue.4'>
              {humanFormattedCountdown}
            </Text>
          )
        }}
      />
    ),
    BodyElement: (
      <>
        {Image}
        {!isPlaceholder && boxPrice && (
          <>
            <CTABox text={t('Unlock now for')} colorPalette='skyblue'>
              <Button
                variant='white'
                onClick={() => {
                  claim(id, handleClaimSuccess)
                }}
              >
                <FormattedMessage
                  id='g7AndAmount'
                  values={{
                    space: <>&nbsp;</>,
                    currencyIcon: <G7Icon name='g7-token-mark-4' pfx='fak' />,
                    currencyAmount: boxPrice
                  }}
                />
              </Button>
            </CTABox>
            {rewards && (
              <ClaimedRewardsModal
                open={step === 'box-rewards'}
                onClose={() => {
                  setStep(null)
                  updateSlotBoxById(id, {
                    variant: 'empty',
                    price: 100,
                    unlockDate: undefined
                  })
                  onUnlock()
                }}
                rewards={rewards}
              />
            )}
            {step === 'box-animation' && (
              <VideoOverlay
                onClose={() => {
                  setStep('box-rewards')
                }}
                sourceUrl='/lootboxes/legendary.webm'
              />
            )}
          </>
        )}
      </>
    )
  }
}

export default Unlocking
