import { showNotification } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { useGqlMutation } from '@api/gql/gqlServices'
import { PurchaseBoxDocument } from '@generated/generates'
import type { PurchaseBoxMutation } from '@generated/generates'
import { useViewer } from '@hooks'
import useG7SlotBoxes from '../../hooks/useG7SlotBoxes'

const usePurchaseSlotBox = (id: string, onSuccess: () => void) => {
  const t = useTranslation()
  const { isAuthenticated, refetch: refetchUserInfo } = useViewer()
  const { refetch: refetchSlotBoxes } = useG7SlotBoxes({ enabled: isAuthenticated })
  const { mutateAsync: purchaseSlotBox, isPending: isProcessing } = useGqlMutation<PurchaseBoxMutation>(
    PurchaseBoxDocument,
    undefined,
    {
      onSuccess: () => {
        refetchSlotBoxes()
        refetchUserInfo()
        onSuccess()
      },
      onError: () => {
        showNotification({
          variant: 'danger',
          message: t('Oops! We could not process this transaction. Please try again')
        })
      }
    }
  )

  const handlePurchaseSlotBox = () => {
    purchaseSlotBox({ slotId: id })
  }

  return {
    purchaseSlotBox: handlePurchaseSlotBox,
    isProcessing
  }
}

export default usePurchaseSlotBox
