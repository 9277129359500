import type { ReactNode } from 'react'
import { useTranslation } from 'summon-ui/intl'
import { Text } from 'summon-ui/mantine'
import type { SlotBoxVariantElementsComponent } from './types'

interface Props {
  Image: ReactNode
}

const Ready = ({ Image }: Props): SlotBoxVariantElementsComponent => {
  const t = useTranslation()
  return {
    TitleElement: (
      <Text fw='bold' size='xs' c='green.2'>
        {t('Ready to claim')}
      </Text>
    ),
    BodyElement: Image
  }
}

export default Ready
