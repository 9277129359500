import { create } from 'zustand'

// Define the type for the step states, including 'null'
type ClaimG7BoxStep = 'box-animation' | 'box-rewards' | null

// Define the state shape for the hook
type IClaimG7BoxStepsState = {
  step: ClaimG7BoxStep
  getStep: () => ClaimG7BoxStep
  setStep: (step: ClaimG7BoxStep) => void
}

// Create the Zustand store
const useClaimG7BoxSteps = create<IClaimG7BoxStepsState>()((set, get) => ({
  // Initial step is set to null
  step: null,

  // Getter method for retrieving the current step
  getStep: () => get().step,

  // Setter method for updating the step
  setStep: (step: ClaimG7BoxStep) => {
    set({ step })
  }
}))

export default useClaimG7BoxSteps
