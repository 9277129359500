import { G7Icon } from 'summon-ui'
import { FormattedMessage, useTranslation } from 'summon-ui/intl'
import { Modal, Button, Text, Stack, Group, Image, Title, Flex, NumberFormatter, Paper } from 'summon-ui/mantine'
import { useUserDiamondsBalance } from '@hooks/balances'
import usePurchaseSlotBox from './usePurchaseSlotBox'

interface Props {
  open: boolean
  onClose: () => void
  slotBoxId: string
  price: number
}

const PurchaseSlotBoxModal = ({ slotBoxId, price, open, onClose }: Props) => {
  const t = useTranslation()
  const { balance: userDiamondsBalance, isLoading: isLoadingUserDiamondsBalance } = useUserDiamondsBalance()
  const { purchaseSlotBox, isProcessing } = usePurchaseSlotBox(slotBoxId, onClose)

  return (
    <Modal opened={open} onClose={onClose} withCloseButton={false} radius='md'>
      <Stack gap='xl' p='md'>
        <Stack gap='lg' align='center'>
          <Stack gap={2} align='center'>
            <Image src='/images/diamonds/box-with-bg.png' alt='success' mb='md' />
            <Paper style={{ backdropFilter: 'blur(10px)' }} withBorder className='!border-skyblue-3' p='xs' radius='md'>
              <Flex gap={6} w='100%' align='center' c='skyblue.3'>
                <G7Icon name='diamonds' pfx='fak' />
                <Flex w='100%' align='center'>
                  <Text fw={800}>{t('Balance')}:&nbsp;</Text>
                  <NumberFormatter className='font-bold' value={userDiamondsBalance} thousandSeparator />
                </Flex>
              </Flex>
            </Paper>
          </Stack>
          <Stack gap={2} align='center'>
            <Title order={3}>{t('Confirm purchase')}</Title>
            <Text ta='center' maw={386}>
              {t('The purchase of a G7 box is going to cost about 100 Diamonds. Click “BUY NOW” to proceed')}
            </Text>
          </Stack>
        </Stack>
        <Group justify='space-between' wrap='nowrap'>
          <Button w='100%' variant='outline' disabled={isProcessing} loading={isProcessing} onClick={onClose}>
            {t('Close')}
          </Button>
          <Button
            w='100%'
            variant='white'
            loading={isProcessing || isLoadingUserDiamondsBalance}
            onClick={() => purchaseSlotBox()}
            disabled={isProcessing || isLoadingUserDiamondsBalance}
          >
            <FormattedMessage
              id='buyNowSlotBoxFormattedMessage'
              values={{
                space: <>&nbsp;</>,
                currencyIcon: <G7Icon name='diamonds' pfx='fak' />,
                currencyAmount: price
              }}
            />
          </Button>
        </Group>
      </Stack>
    </Modal>
  )
}

export default PurchaseSlotBoxModal
