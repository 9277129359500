import { useGqlQuery } from '@api/gql/gqlServices'
import { GetBoxSlotsDocument, type GetBoxSlotsQuery } from '@generated/generates'
import type { SlotBoxes } from '../types'

const useG7SlotBoxes = ({ enabled }: { enabled: boolean }) => {
  const { isLoading, isSuccess, isError, refetch, data } = useGqlQuery<GetBoxSlotsQuery>(
    GetBoxSlotsDocument,
    {},
    {
      enabled
    }
  )

  const slotBoxes = data?.getBoxSlots == null ? [null, null, null, null] : data?.getBoxSlots

  return {
    slotBoxes: slotBoxes as SlotBoxes,
    isLoading,
    isSuccess,
    isError,
    refetch
  }
}

export default useG7SlotBoxes
