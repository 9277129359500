import type { ReactNode } from 'react'
import clsx from 'clsx'
import { Countdown, useMediaScreen } from 'summon-ui'
import { Image, Stack, Paper, Title, Box, Flex } from 'summon-ui/mantine'
import styles from './boxTemplate.module.css'

const G7_BOX_SIZE = 320

interface Props {
  countdownDate?: string
  ActionCTA?: ReactNode
  description: ReactNode
  isBoxBlurred?: boolean
}

const BoxTemplate = ({ countdownDate, ActionCTA, description, isBoxBlurred = false }: Props) => {
  const isSmallerThanMiniTablet = useMediaScreen({ type: 'max', breakpoint: 'tablet' })

  return (
    <Box h={G7_BOX_SIZE}>
      <Stack gap='0' justify='start' h='100%' align='center'>
        <Box className={clsx({ [styles.grayBlurry]: isBoxBlurred })}>
          <Image src='/images/diamonds/box.png' w={G7_BOX_SIZE} />
        </Box>
        <Stack align='center' gap={0} pos='relative' h='100%' w='100%'>
          {(description || countdownDate) && (
            <Paper
              className={styles.infoContainer}
              bg='transparent'
              pos='absolute'
              bottom={isSmallerThanMiniTablet ? 50 : 45}
              radius='sm'
              p='md'
              ta='center'
            >
              {description && <Box>{description}</Box>}
              {countdownDate && (
                <Countdown
                  key={countdownDate}
                  from={new Date(countdownDate)}
                  render={(humanFormattedCountdown) => {
                    return <Title order={1}>{humanFormattedCountdown}</Title>
                  }}
                />
              )}
            </Paper>
          )}
          <Flex w='100%' h='40' align='center'>
            {ActionCTA}
          </Flex>
        </Stack>
      </Stack>
    </Box>
  )
}

export default BoxTemplate
