import { useEffect } from 'react'
import { showNotification } from 'summon-ui'
import { formatUnits } from 'viem'
import { useAccount, useBalance, useBlockNumber, useReadContract } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'
import { graphqlClient } from '@api/config'
import { TENANT_CONTRACTS, TENANT_NETWORK } from '@config'
import { AVATAR_BOUND_ABI } from '@constants/Abi/avatarBoundAbi'
import useOnboardingUtils from '@features/onboarding/hooks/useOnboardingUtils'
import { useOnboardingStorage } from '@features/onboarding/onboarding.store'
import { useMintAvatarMutation } from '@generated/generates'

const MIN_BALANCE_TO_SPONSOR = 0.025
const useMintAvatar = () => {
  const { saveAvatar, isSavingAvatar } = useOnboardingUtils()
  const { address } = useAccount()
  const queryClient = useQueryClient()
  const { selectedOnboardingSkin } = useOnboardingStorage()
  const { data: tokenIdData, refetch } = useReadContract({
    address: TENANT_CONTRACTS.avatar,
    abi: AVATAR_BOUND_ABI,
    chainId: TENANT_NETWORK.id,
    functionName: 'tokenOfOwnerByIndex',
    // We need to use the account connected here because the contract does not accept address in lowercase (like how we save it in the viewer)
    args: [address, 0],
    query: { enabled: !!address }
  })
  const baseSkinId = Number(selectedOnboardingSkin?.tokenId)

  const { data: blockNumber } = useBlockNumber({ watch: !tokenIdData, chainId: TENANT_NETWORK.id })

  useEffect(() => {
    if (!tokenIdData) {
      refetch()
    } else {
      const saveAndMarkAsClaimed = async () => {
        await saveAvatar({ tokenId: Number(tokenIdData) as number, skinId: selectedOnboardingSkin?.glbUrl as string })
      }
      saveAndMarkAsClaimed()
    }
  }, [blockNumber, tokenIdData])
  const { data } = useBalance({
    address: TENANT_CONTRACTS.achievoWallet,
    chainId: TENANT_NETWORK.id
  })

  const hasBalance = data ? Number(formatUnits(data.value, data.decimals)) > MIN_BALANCE_TO_SPONSOR : null

  const { mutateAsync: mintAvatarMutation, isPending: isMinting } = useMintAvatarMutation(graphqlClient(), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['GetUser'] })
      showNotification({
        variant: 'success',
        message: 'Avatar minted successfully!'
      })
    },
    onError: () => {
      showNotification({
        variant: 'danger',
        message: 'Something went wrong, try again later!'
      })
    }
  })
  return {
    hasBalanceToSponsor: hasBalance,
    mintAvatarMutation: () => mintAvatarMutation({ baseSkinId }),
    isMinting: isMinting || isSavingAvatar
  }
}

export default useMintAvatar
