import { useState } from 'react'
import { G7Icon } from 'summon-ui'
import { FormattedMessage, useTranslation } from 'summon-ui/intl'
import { Text, Title, Stack, Button, Flex } from 'summon-ui/mantine'
import BoxTemplate from '../BoxTemplate/BoxTemplate'
import PurchaseSlotBoxModal from './PurchaseSlotBoxModal'

interface Props {
  slotBoxId: string
  price: number
}

const PurchaseSlotBox = ({ price, slotBoxId }: Props) => {
  const t = useTranslation()

  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false)

  const renderCTA = () => (
    <Flex justify='center' gap='sm'>
      <Button variant='outline' bg='transparent'>
        {t('Learn More')}
      </Button>
      <Button
        variant='white'
        onClick={() => {
          setIsPurchaseModalOpen(true)
        }}
      >
        <FormattedMessage
          id='buyNowSlotBoxFormattedMessage'
          values={{
            space: <>&nbsp;</>,
            currencyIcon: <G7Icon name='diamonds' pfx='fak' />,
            currencyAmount: price
          }}
        />
      </Button>
    </Flex>
  )

  return (
    <>
      <BoxTemplate
        ActionCTA={renderCTA()}
        isBoxBlurred
        description={
          <Stack gap={0}>
            <Text>{t('Unlocks rare rewards with a')}</Text>
            <Title order={2} fw='bold'>
              G7 BOX
            </Title>
          </Stack>
        }
      />
      <PurchaseSlotBoxModal
        slotBoxId={slotBoxId}
        price={price}
        open={isPurchaseModalOpen}
        onClose={() => setIsPurchaseModalOpen(false)}
      />
    </>
  )
}

export default PurchaseSlotBox
