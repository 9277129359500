import type { FC } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAccountEffect } from 'wagmi'
import { useLogout, useLogin, useViewer, useReferral } from '@hooks'

const withAuth = (WrappedComponent: FC<{ isAuthenticating?: boolean }>) => {
  const NewComponent = () => {
    const { pathname } = useLocation()
    useReferral()
    // sign message when user connects wallet
    const { login } = useLogin()
    const { logout } = useLogout()
    useAccountEffect({
      onConnect: login,
      onDisconnect: logout
    })
    const { viewer, isLoading } = useViewer()
    const isOnboarding = viewer && !viewer?.isOnboardingCompleted

    return (
      <>
        {isOnboarding ? (
          <Navigate to='/onboarding' state={{ redirect: pathname }} />
        ) : (
          <WrappedComponent isAuthenticating={isLoading} />
        )}
      </>
    )
  }

  NewComponent.displayName = 'withAuth'

  return NewComponent
}

export default withAuth
