import { useState } from 'react'
import { useMediaScreen } from 'summon-ui'
import { Center, Flex, Stack } from 'summon-ui/mantine'
import { PageCenteredWrapper } from '@components'
import { useViewer } from '@hooks'
import ActionCards from '../components/ActionCards'
import DiamondsSignup from '../components/DiamondsSignup'
import G7Box from '../components/G7Box/G7Box'
import OnboardingModal from '../components/OnboardingModal'
import PurchaseSlotBox from '../components/PurchaseSlotBox/PurchaseSlotBox'
import SlotBoxes from '../components/SlotBoxes/SlotBoxes'
import useG7SlotBoxes from '../hooks/useG7SlotBoxes'
import type { SlotBoxes as SlotBoxesType } from '../types'
import { MOCKED_EMPTY_SLOTBOX_PRICE } from '../types'

const MIDDLE_INFO_SPACE_TOP = 80

const slotBoxesPlaceholder: SlotBoxesType = [{ id: '0', userId: '?', boxDetails: null }, null, null, null]

const DiamondsView = () => {
  const { isLoading: isLoadingViewer, isAuthenticated } = useViewer()
  const [selectedSlotIndex, setSelectedSlotIndex] = useState<number>(0)
  const isMiniTablet = useMediaScreen({ type: 'max', breakpoint: 'tablet' })
  const { slotBoxes, isLoading: isLoadingSlotBoxes } = useG7SlotBoxes({ enabled: isAuthenticated })

  const renderSlotBoxContent = (slotBoxes: SlotBoxesType) => {
    const selectedSlotBox = slotBoxes[selectedSlotIndex]
    if (selectedSlotBox === null) {
      // This will not happen as of today cause this could only be a selected coming-soon slotbox, which is not possible
      // When we can buy empty slot boxes from coming-soon we will display here that view
      return null
    }

    return selectedSlotBox?.boxDetails != null ? (
      <G7Box
        g7BoxId={selectedSlotBox.id}
        unlockAt={selectedSlotBox.boxDetails.unlockAt}
        onClaimSuccess={() => {
          setSelectedSlotIndex(0)
        }}
      />
    ) : (
      <PurchaseSlotBox slotBoxId={selectedSlotBox.id} price={MOCKED_EMPTY_SLOTBOX_PRICE} />
    )
  }

  return isLoadingSlotBoxes || isLoadingViewer ? (
    <PageCenteredWrapper />
  ) : (
    <>
      <Stack pos='relative' align='center' gap='xl' pt={isMiniTablet ? 0 : MIDDLE_INFO_SPACE_TOP}>
        {isAuthenticated && !isMiniTablet && (
          <Flex justify='space-between' pos='absolute' top={0} w='100%'>
            <ActionCards />
          </Flex>
        )}
        <Center w='100%'>{isAuthenticated && slotBoxes ? renderSlotBoxContent(slotBoxes) : <DiamondsSignup />}</Center>
        <Stack align='center' w='100%'>
          {isMiniTablet && isAuthenticated && <ActionCards isSmallVariant />}
          <SlotBoxes
            slotBoxes={isAuthenticated && slotBoxes ? slotBoxes : slotBoxesPlaceholder}
            selectedSlotIndex={selectedSlotIndex}
            setSelectedSlotIndex={setSelectedSlotIndex}
            isPlaceholder={isAuthenticated ? false : true}
          />
        </Stack>
      </Stack>
      <OnboardingModal />
    </>
  )
}

export default DiamondsView
