import { useState } from 'react'

// Define the type for the rewards
export type G7Reward = {
  amount: number
  name: string
  imgUrl: string
}

// Mock reward data
const mockRewards: G7Reward[] = [
  {
    amount: 100,
    name: 'USDC',
    imgUrl: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=035'
  },
  {
    amount: 6592,
    name: 'G7',
    imgUrl: '/images/diamonds/g7Coin-to-be-removed.png'
  }
]

const useClaimG7Box = () => {
  const [isClaiming, setIsClaiming] = useState(false)

  const [rewards, setRewards] = useState<G7Reward[] | null>(null)

  const claim = (g7BoxId: string, onSuccess: () => void) => {
    // Set claiming to true when claim is initiated
    setIsClaiming(true)

    // Simulate a backend delay of 2 seconds
    setTimeout(() => {
      // After 2 seconds, set the mock rewards and stop claiming state
      setRewards(mockRewards)
      setIsClaiming(false)
      onSuccess()
    }, 2000)
  }

  return {
    claim,
    isClaiming,
    rewards
  }
}

export default useClaimG7Box
