import { showNotification } from 'summon-ui'
import { useGqlMutation, useGqlQuery } from '@api/gql/gqlServices'
import {
  OpenDailyDiamondBoxDocument,
  GetDailyDiamondBoxStatusDocument,
  type GetDailyDiamondBoxStatusQuery,
  type OpenDailyDiamondBoxMutation
} from '@generated/generates'

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong'
export const SUCCESS_CLAIM_MESSAGE = 'Daily diamonds claimed successfully!'

const useDailyClaim = () => {
  const {
    data: dailyDiamondBoxStatusData,
    isLoading: isLoadingDailyDiamondBoxStatusData,
    refetch
  } = useGqlQuery<GetDailyDiamondBoxStatusQuery>(GetDailyDiamondBoxStatusDocument)

  const { mutateAsync: claimDailyDiamond, isPending: isClaimMutationPending } =
    useGqlMutation<OpenDailyDiamondBoxMutation>(OpenDailyDiamondBoxDocument, undefined)

  const claim = async ({ onSuccess }: { onSuccess: (diamondsReward: number) => void }) => {
    try {
      const response = await claimDailyDiamond({})
      refetch()
      onSuccess(response.openDailyDiamondBox)
    } catch {
      showNotification({
        variant: 'danger',
        message: DEFAULT_ERROR_MESSAGE
      })
    }
  }

  // Set to next 00:00 UTC by adding 1 day to now and resetting the time
  const now = new Date()
  const nextMidnightUTC = new Date(now.setUTCDate(now.getUTCDate() + 1))
  nextMidnightUTC.setUTCHours(0, 0, 0, 0)

  return {
    isLoadingCanClaim: isLoadingDailyDiamondBoxStatusData,
    canClaim: !!dailyDiamondBoxStatusData?.getDailyDiamondBoxStatus.canOpenDailyBox,
    claim,
    isClaiming: isClaimMutationPending,
    unlockDate: nextMidnightUTC.toString()
  }
}

export default useDailyClaim
