import { useState } from 'react'
import { useMediaScreen } from 'summon-ui'
import { Box, Carousel, Flex } from 'summon-ui/mantine'
import type { BoxSlot as SlotBoxType } from '@generated/generates'
import type { SlotBoxes as SlotBoxesType } from '../../types'
import SlotBox from './SlotBox'
import { getSlotBoxUIDisplayVariant } from './helpers'

interface SlotBoxesProps {
  slotBoxes: SlotBoxesType
  selectedSlotIndex: number
  setSelectedSlotIndex: (index: number) => void
  isPlaceholder?: boolean
}

const SlotBoxes = ({ slotBoxes, selectedSlotIndex, setSelectedSlotIndex, isPlaceholder = false }: SlotBoxesProps) => {
  const isMiniTablet = useMediaScreen({ type: 'max', breakpoint: 'tablet' })
  const [numberOfDynamicUnlocks, setNumberOfDynamicUnlocks] = useState(0)

  const renderSlotBox = (slotBox: SlotBoxType | null, isSelected: boolean, onSelect: () => void) => {
    const displayVariant = slotBox ? getSlotBoxUIDisplayVariant(slotBox) : 'coming-soon'

    return slotBox ? (
      <SlotBox
        isPlaceholder={isPlaceholder}
        isSelected={isSelected}
        onSelect={onSelect}
        slotBox={slotBox}
        displayVariant={displayVariant}
        {...(displayVariant === 'unlocking'
          ? {
              onUnlock: () => {
                setSelectedSlotIndex(0)
              },
              onUnlockAtOver: () => {
                setNumberOfDynamicUnlocks((prev) => prev + 1)
              }
            }
          : {})}
      />
    ) : (
      <SlotBox slotBox={null} isPlaceholder={isPlaceholder} isSelected={false} displayVariant={displayVariant} />
    )
  }

  const SlotBoxElements = slotBoxes.map((slotBox, index) => {
    const handleSelectSlotBox = () => {
      setSelectedSlotIndex(index)
    }

    const key = slotBox ? `${slotBox.id}-${numberOfDynamicUnlocks}-${selectedSlotIndex}` : `coming-soon-${index}`

    // Rendering Carousel Slide if on tablet, otherwise return Box layout
    return isMiniTablet ? (
      <Carousel.Slide key={key} p='xs'>
        {renderSlotBox(slotBox, index === selectedSlotIndex, handleSelectSlotBox)}
      </Carousel.Slide>
    ) : (
      <Box key={key}>{renderSlotBox(slotBox, index === selectedSlotIndex, handleSelectSlotBox)}</Box>
    )
  })

  return isMiniTablet ? (
    <Box w='100%'>
      <Carousel
        controlsOffset='xs'
        controlSize={14}
        loop
        dragFree
        slideSize={{ xs: '35%' }}
        slideGap='xs'
        align='start'
        slidesToScroll={1}
      >
        {SlotBoxElements}
      </Carousel>
    </Box>
  ) : (
    <Flex gap='sm'>{SlotBoxElements}</Flex>
  )
}

export default SlotBoxes
