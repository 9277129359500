import type { ReactNode } from 'react'
import type { MantineColor } from 'summon-ui/mantine'
import { Paper, Overlay, Text, alpha, Stack, Box } from 'summon-ui/mantine'

const CTABox = ({
  text,
  children,
  colorPalette
}: {
  text: string
  children: ReactNode
  colorPalette: MantineColor
}) => {
  return (
    <Paper pos='absolute' radius='md' bottom={0} w='90%' p='xs' m='xs' bg='transparent'>
      <Overlay
        radius='md'
        zIndex={0}
        backgroundOpacity={0.7}
        blur={8}
        bg={alpha(`var(--mantine-color-${colorPalette}-1)`, 0.1)}
      />
      <Stack gap={8}>
        <Box h={20} pos='relative'>
          <Text ta='center' size='xs' w='100%' c={`${colorPalette}.2`} pos='absolute' bottom={0}>
            {text}
          </Text>
        </Box>
        {children}
      </Stack>
    </Paper>
  )
}

export default CTABox
