import { useViewer } from '@hooks'

const useUserDiamondsBalance = () => {
  const { viewer, isLoading } = useViewer()

  return {
    balance: Number(viewer?.diamonds) || 0,
    isLoading
  }
}

export default useUserDiamondsBalance
