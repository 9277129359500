import { useState, type ReactNode } from 'react'
import { G7Icon } from 'summon-ui'
import { FormattedMessage, useTranslation } from 'summon-ui/intl'
import { Button, Text } from 'summon-ui/mantine'
import PurchaseSlotBoxModal from '../../../components/PurchaseSlotBox/PurchaseSlotBoxModal'
import CTABox from '../../../components/SlotBoxes/variants/CTABox'
import type { SlotBoxVariantElementsComponent } from './types'

interface Props {
  id: string
  Image: ReactNode
  price?: number
  isPlaceholder: boolean
}

const Empty = ({ id, Image, price, isPlaceholder }: Props): SlotBoxVariantElementsComponent => {
  const t = useTranslation()
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false)

  return {
    TitleElement: (
      <Text fw='bold' size='xs' c='yellow.2'>
        {t('Empty slot')}
      </Text>
    ),
    BodyElement: (
      <>
        {Image}
        {!isPlaceholder && price && (
          <CTABox text={t('Buy box for')} colorPalette='yellow'>
            <Button
              variant='white'
              onClick={() => {
                setIsPurchaseModalOpen(true)
              }}
            >
              <FormattedMessage
                id='diamondAndAmount'
                values={{
                  space: <>&nbsp;</>,
                  currencyIcon: <G7Icon name='diamonds' pfx='fak' />,
                  currencyAmount: price
                }}
              />
            </Button>
          </CTABox>
        )}
        {price && (
          <PurchaseSlotBoxModal
            slotBoxId={id}
            price={price}
            open={isPurchaseModalOpen}
            onClose={() => setIsPurchaseModalOpen(false)}
          />
        )}
      </>
    )
  }
}

export default Empty
