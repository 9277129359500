import { useTranslation } from 'summon-ui/intl'
import { Button, Title, Flex, Stack, Text } from 'summon-ui/mantine'
import { ConnectButton } from '@components'
import BoxTemplate from './BoxTemplate/BoxTemplate'

const DiamondsSignup = () => {
  const t = useTranslation()

  const renderCTA = () => (
    <Flex justify='center' gap='sm' w={400}>
      <Button variant='outline' bg='transparent' w='100%'>
        {t('Learn More')}
      </Button>
      <ConnectButton />
    </Flex>
  )

  return (
    <BoxTemplate
      ActionCTA={renderCTA()}
      isBoxBlurred
      description={
        <Stack gap={0}>
          <Text>{t('Unlocks rare rewards with a')}</Text>
          <Title order={2} fw='bold'>
            G7 BOX
          </Title>
        </Stack>
      }
    />
  )
}

export default DiamondsSignup
