import { create } from 'zustand'
import type { SlotBoxUIDisplayVariant } from '@features/diamonds/types'

export type SlotBox = {
  id: string
  variant: SlotBoxUIDisplayVariant
  unlockDate?: string
  price?: number
}

// Helper function to generate future date strings
const getFutureDateInStringFormat = (milisecondsFromNow: number) => {
  const now = new Date()
  const futureDate = new Date(now.getTime() + milisecondsFromNow)
  return futureDate.toString()
}

// Mocked data for slot boxes (this simulates what you would fetch from a backend)
const mockedSlotsData: SlotBox[] = [
  { id: 'emptyBoxId', variant: 'empty', price: 100 },
  { id: 'readyBoxId', variant: 'ready', unlockDate: new Date(new Date().getTime() - 100).toString() },
  { id: 'unlockingBoxId', variant: 'unlocking', unlockDate: getFutureDateInStringFormat(3220000), price: 150 },
  { id: 'comingSoonBoxId', variant: 'coming-soon' }
]

// Define Zustand store to simulate backend storage
type IMockedSlotsState = {
  slotBoxes: SlotBox[]
  isLoading: boolean
  setSlotBoxes: (slotBoxes: SlotBox[]) => void
  queryMockedSlotBoxes: () => Promise<void>
  updateSlotBoxById: (id: string, updatedBox: Partial<SlotBox>) => void
}

// Zustand store that simulates the backend logic
const useMockedSlotsStorage = create<IMockedSlotsState>((set, get) => ({
  slotBoxes: [], // Initially empty, will be filled by the query
  isLoading: false, // Loading state for the data fetching
  setSlotBoxes: (slotBoxes: SlotBox[]) => {
    set({ slotBoxes })
  },
  // Function to simulate querying slot boxes (with a delay)
  queryMockedSlotBoxes: async () => {
    set({ isLoading: true })
    await new Promise((resolve) => setTimeout(resolve, 1000)) // Simulate a 1-second backend delay
    set({ slotBoxes: mockedSlotsData, isLoading: false }) // Set the mocked data after delay
  },
  // Function to update a specific slot box by ID
  updateSlotBoxById: (id: string, updatedBox: Partial<SlotBox>) => {
    const currentSlots = get().slotBoxes
    const updatedSlots = currentSlots.map((slot) => (slot.id === id ? { ...slot, ...updatedBox } : slot))
    set({ slotBoxes: updatedSlots })
  }
}))

export default useMockedSlotsStorage
