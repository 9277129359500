import { useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import {
  Modal,
  Button,
  Title,
  Text,
  Stack,
  Image,
  Flex,
  NumberFormatter,
  BackgroundImage,
  Paper
} from 'summon-ui/mantine'
import type { G7Reward } from '../../hooks/useClaimG7Box'

const MOBILE_IMG_CARD_SIZE = 150
const MOBILE_IMG_REWARD_SIZE = 60
const IMG_CARD_SIZE = 250
const IMG_REWARD_SIZE = 100

interface Props {
  open: boolean
  onClose: () => void
  rewards: G7Reward[]
}

const ClaimedRewardsModal = ({ open, onClose, rewards }: Props) => {
  const t = useTranslation()
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'mini' })

  return (
    <Modal opened={open} onClose={onClose} withCloseButton={false} radius='md' size='auto'>
      <Stack gap='sm' align='center'>
        <Title order={3}>{t('Reward')}</Title>
        <Stack gap='lg' align='center'>
          <Flex gap={isMobile ? 'sm' : 'xl'} align='center'>
            {rewards.map((reward) => {
              return (
                <Paper withBorder key={reward.name} radius='lg' className='overflow-hidden'>
                  <BackgroundImage
                    src='/images/diamonds/reward-card-bg.png'
                    h={isMobile ? MOBILE_IMG_CARD_SIZE : IMG_CARD_SIZE}
                    w={isMobile ? MOBILE_IMG_CARD_SIZE : IMG_CARD_SIZE}
                    className='rotate-90'
                  >
                    <Stack align='center' py='sm' h='100%' justify='center'>
                      <Image
                        src={reward.imgUrl}
                        alt='success'
                        h={isMobile ? MOBILE_IMG_REWARD_SIZE : IMG_REWARD_SIZE}
                        w={isMobile ? MOBILE_IMG_REWARD_SIZE : IMG_REWARD_SIZE}
                      />
                      <Flex w='100%' justify='center' align='center' gap={6}>
                        <NumberFormatter className='font-bold text-lg' value={reward.amount} thousandSeparator />
                        <Text size='lg' fw={800}>
                          {reward.name}
                        </Text>
                      </Flex>
                    </Stack>
                  </BackgroundImage>
                </Paper>
              )
            })}
          </Flex>
          <Button variant='outline' onClick={onClose} w='100%'>
            {t('Back to diamonds store')}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default ClaimedRewardsModal
