import clsx from 'clsx'
import { alpha, Center, Overlay, Paper } from 'summon-ui/mantine'
import type { Maybe, BoxSlot as SlotBoxType } from '@generated/generates'
import type { SlotBoxUIDisplayVariant } from '../../types'
import { getElementsSlotBoxVariantRenderElements, getVariantColor } from './helpers'
import styles from './slotBox.module.css'

export const SLOT_BOX_H = 160
export const SLOT_BOX_W = 144

export interface Props {
  displayVariant: SlotBoxUIDisplayVariant
  slotBox: Maybe<SlotBoxType>
  isSelected: boolean
  onSelect?: () => void
  onUnlockAtOver?: () => void
  onUnlock?: () => void
  isPlaceholder?: boolean
  isComingSoon?: boolean
}

const SlotBox = ({
  displayVariant,
  slotBox,
  isSelected,
  onSelect,
  onUnlockAtOver,
  onUnlock,
  isComingSoon = false,
  isPlaceholder = false
}: Props) => {
  const { TitleElement, BodyElement } = getElementsSlotBoxVariantRenderElements({
    displayVariant,
    slotBox,
    onUnlockAtOver,
    onUnlock,
    isPlaceholder,
    isComingSoon
  })

  return (
    <Paper
      data-variant={displayVariant} // For the displayVariant-specific styles
      data-selected={isSelected} // Boolean attribute for selection
      data-disabled={isPlaceholder}
      withBorder
      radius='lg'
      className={clsx(styles.box, 'overflow-hidden')}
      w={SLOT_BOX_W}
      h={SLOT_BOX_H}
      {...(displayVariant !== 'coming-soon' ? { onClick: onSelect } : {})}
    >
      <Paper
        w='100%'
        h='100%'
        bg={alpha(`var(--mantine-color-${getVariantColor(displayVariant)}-6)`, isSelected ? 0.3 : 0.1)}
        pos='absolute'
      />
      <Center>
        <Paper
          data-variant={displayVariant} // For the variant-specific styles
          data-selected={isSelected}
          data-disabled={isPlaceholder}
          radius={0}
          pos='absolute'
          top={0}
          px='md'
          py={0}
          className={clsx(styles.titleBox, 'z-50')}
        >
          {TitleElement}
        </Paper>
      </Center>
      {
        <Center h='100%' w='100%' className='z-10' pos='relative'>
          {displayVariant === 'empty' && <Overlay zIndex='0' backgroundOpacity={0.2} blur={isSelected ? 1 : 2} />}
          {BodyElement}
        </Center>
      }
    </Paper>
  )
}

export default SlotBox
